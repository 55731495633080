import { Box, Button, Divider, TextField } from '@mui/material';
import React from 'react';
import { useAppDispatch } from '../../../../app/store';
import CompanyDropdownV3 from '../../../../common/components/dropdown/CompanyDropdownV3';
import useMutationResponse from '../../../../common/hooks/useMutationResponse';
import { parseTwoDimensionalBarcode } from '../../../../common/utils/twoDimentialBarcodeParser';
import { setDialog } from '../../../notification/notificationSlice';
import textFieldFocus from '../../../rapidQc/utils/textFieldFocus';
import receiversApi from '../../../receivers/receiversApi';
import clearSerialApi from '../../clearSerialApi';
import SkuByUpcDropdown from './SkuByUpcDropdown';

//test case 1: upc hit, then populate sku options : D300048B x
//test case 2: upc hit, single sku hit- prompt for serial only PLEDGE x
//test case 3: upc miss, single sku hit- prompt for serial only and populate upc 300002-E
//test case 4: upc miss, sku miss- do nothing badInput
//test case 5: upc miss, multiple sku hit- error out
const InitialScanForm = () => {
    const [client, setClient] = React.useState('');
    const [initialScan, setInitialScan] = React.useState('');
    const [upc, setUpc] = React.useState('');
    const [sku, setSku] = React.useState('');
    const [serial, setSerial] = React.useState('');
    const [reason, setReason] = React.useState('');

    const dispatch = useAppDispatch();

    const resetResultStates = () => {
        setUpc('');
        setSku('');
        setSerial('');
    };

    const resetAllStates = () => {
        setInitialScan('');
        setReason('');
        resetResultStates();
    };

    const [triggerGetItem, getItem] = receiversApi.endpoints.getItem.useLazyQuery();

    const [
        getBeforeSerialStatusesTrigger,
        getBeforeSerialStatusesResponse,
    ] = clearSerialApi.endpoints.getSerialStatuses.useLazyQuery();

    const [
        getAfterSerialStatusesTrigger,
        getAfterSerialStatusesResponse,
    ] = clearSerialApi.endpoints.getSerialStatuses.useLazyQuery();

    const parseInitialScan = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const parsedBarcode = parseTwoDimensionalBarcode(client, initialScan, false);

        let newUpc = parsedBarcode?.Upc?.trim() || '';
        let newSku = parsedBarcode?.Sku?.trim() || '';
        let newSerial = parsedBarcode?.Serial?.trim() || '';

        await triggerGetItem({ MomCode: client, Upc: newUpc })
            .unwrap()
            .then(response => {
                if (response.Data.length === 1) {
                    newSku = response.Data[0].Sku;
                }
            })
            .catch(async () => {
                await triggerGetItem({ MomCode: client, Sku: newSku || newUpc })
                    .unwrap()
                    .then(response => {
                        if (response?.Data?.length === 1) {
                            newSku = newSku || newUpc;
                            newUpc = response?.Data?.[0].Upc;
                        } else {
                            dispatch(
                                setDialog({
                                    content: 'Sku matches multiple stock items. Please inform IT.',
                                })
                            );
                        }
                    })
                    .catch(() => {});
            });

        setUpc(newUpc);
        setSku(newSku);
        setSerial(newSerial);
    };

    const noSkuOptionsText = `No matching SKU for (Client: ${client} and UPC: ${upc})`;

    const initialScanInputRef = React.useRef<HTMLInputElement>(null);

    const [triggerClearSerials, clearSerialsResponse] = clearSerialApi.endpoints.clearSerials.useMutation();

    useMutationResponse({
        response: clearSerialsResponse,
        errorMessage: 'Failed to clear serial',
        successMessage: 'Successfully cleared serial',
    });

    const handleAddToListSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!sku) {
            dispatch(setDialog({ content: noSkuOptionsText }));
            return;
        }

        if (!serial) {
            dispatch(setDialog({ content: 'Serial is required' }));
            return;
        }

        const beforeSerialStatusResponse = await getBeforeSerialStatusesTrigger({
            Serials: [
                {
                    Client: client,
                    Sku: sku,
                    Serial: serial,
                },
            ],
        });

        const beforeSerialStatus = beforeSerialStatusResponse?.data?.Serials.find(
            responseSerial =>
                responseSerial.Client === client && responseSerial.Sku === sku && responseSerial.Serial === serial
        );

        if (!beforeSerialStatus) {
            dispatch(setDialog({ content: 'Failed to get before serial status' }));
            return;
        }

        if (!beforeSerialStatus.Found) {
            dispatch(setDialog({ content: 'Serial not found' }));
            return;
        }

        if (!beforeSerialStatus.IsActive) {
            dispatch(setDialog({ content: 'Serial already cleared' }));
            return;
        }

        await triggerClearSerials({
            Serials: [
                {
                    Client: client,
                    Sku: sku,
                    Serial: serial,
                },
            ],
            Reason: reason,
        });

        // TODO: uncomment when redux gets updated to 2.0. Currently, there's a bug in 1.X that breaks this code
        // const afterSerialStatusResponse = await getAfterSerialStatusesTrigger({
        //     Serials: [
        //         {
        //             Client: client,
        //             Sku: sku,
        //             Serial: serial,
        //         },
        //     ],
        // });

        // const afterSerialStatus = afterSerialStatusResponse?.data?.Serials.find(
        //     responseSerial =>
        //         responseSerial.Client === client && responseSerial.Sku === sku && responseSerial.Serial === serial
        // );

        // if (!afterSerialStatus) {
        //     dispatch(setDialog({ content: 'Failed to get after serial status' }));
        //     return;
        // }

        // if (afterSerialStatus.IsActive) {
        //     dispatch(setDialog({ content: 'Serial failed to clear' }));
        //     return;
        // }

        resetAllStates();

        textFieldFocus({ textFieldRef: initialScanInputRef });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <form onSubmit={parseInitialScan}>
                <CompanyDropdownV3
                    value={client}
                    onChange={(e, company) => {
                        setClient(company?.mom || '');
                    }}
                    textFieldProps={{ label: 'Client', required: true, variant: 'filled' }}
                    sx={{ m: 1 }}
                />

                <Box sx={{ display: 'flex' }}>
                    <TextField
                        value={initialScan}
                        onChange={e => {
                            const newValue = e.target.value;
                            setInitialScan(newValue);
                        }}
                        label="Scan UPC, SKU, or 2D Barcode"
                        name="Barcode"
                        sx={{ flex: 3, mx: 1 }}
                        required
                        variant="filled"
                        inputRef={initialScanInputRef}
                    />
                    <Button color="secondary" type="submit" sx={{ p: 2 }} variant="contained">
                        Validate
                    </Button>
                </Box>
            </form>

            <Divider flexItem sx={{ my: 2 }} />

            <form onSubmit={handleAddToListSubmit}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex' }}>
                        <TextField
                            value={upc}
                            label="Upc"
                            name="Upc"
                            sx={{ flex: 1, mx: 1 }}
                            variant="filled"
                            helperText=" "
                            required
                            disabled={!upc}
                        />

                        {((getItem.isSuccess && getItem?.data?.Data?.length) || 0) > 1 ? (
                            <SkuByUpcDropdown
                                client={client}
                                value={sku}
                                onChange={(event, value) => {
                                    if (value?.Sku) setSku(value.Sku);
                                }}
                                sx={{ flex: 1, mx: 1 }}
                                disabled={!initialScan}
                                textFieldProps={{ label: 'Sku', name: 'Sku', variant: 'filled', required: true }}
                                noOptionsText={noSkuOptionsText}
                                getItemResponse={getItem}
                                key={sku}
                            />
                        ) : (
                            <TextField
                                value={sku}
                                label="Sku"
                                name="Sku"
                                sx={{ flex: 1, mx: 1 }}
                                variant="filled"
                                helperText={!sku ? noSkuOptionsText : ' '}
                                disabled={!sku}
                                required
                            />
                        )}

                        <TextField
                            value={serial}
                            onChange={e => {
                                const newValue = e.target.value;
                                setSerial(newValue.trim());
                            }}
                            label="Serial"
                            name="Serial"
                            sx={{ flex: 1, mx: 1 }}
                            variant="filled"
                            helperText=" "
                            disabled={!sku}
                            required
                        />
                    </Box>

                    <Divider flexItem sx={{ my: 2 }} />

                    <TextField
                        label="Reason"
                        variant="filled"
                        sx={{ my: 2, width: '100%' }}
                        name="Reason"
                        required
                        value={reason}
                        onChange={event => {
                            const newValue = event.target.value;
                            setReason(newValue);
                        }}
                    />

                    <Button type="submit" sx={{ flex: 1, mx: 1 }} variant="contained" color="primary">
                        Clear Serial
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default InitialScanForm;
